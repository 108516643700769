

































































































































































































import { Component, Vue } from "vue-property-decorator";
import { IUserProfile, IUserProfileUpdate } from "@/interfaces";
import { dispatchGetUsers, dispatchEditAddress } from "@/store/admin/actions";
import { readAdminOneUser } from "@/store/admin/getters";
import _ from "lodash";
import { formatAddress } from "../../../utils";
import VuetifyGoogleAutocomplete from "../../VuetifyGoogleAutocomplete.vue";
import { dispatchUploadAdminDocs } from "@/store/admin/actions";
import { readServiceAddress, readBillingAddress } from "@/store/main/getters";
import VueUploadMultipleImage from "../../VueUploadMultipleImage.vue";
import UploadButton from "../../../components/UploadButton.vue";

import Loading from "../../Loading.vue";

@Component({
  components: {
    VuetifyGoogleAutocomplete,
    VueUploadMultipleImage,
    Loading,
  },
  props: {
    editInfo: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
    },
    onClose: {
      type: Function,
    },
  },
  watch: {
    user: function (newVal, oldVal) {
      // watch it
      console.log("Prop changed: ", newVal, " | was: ", oldVal);
      const call = _.get(this, "reset", () => null);
      call();
    },
  },
})
export default class EditAddress extends Vue {
  public valid = true;
  public user_id = 0;
  public unit = "";
  public address = "";
  public place = "";
  public placeId = "";
  public addressInfo = "";
  public addressData = "";
  public showLoading = false;
  public tip = "";

  public doc_types = [
    "ID Document",
    "Proof of Residence Document",
    "CAF Document",
    "Billing Document",
    "Other Document",
  ];
  public images = [];
  public showPrimary = false;
  public multiple = true;
  public is_foreign = false;

  public IDFiles = Array();
  public BillFiles = Array();

  public doc_type = "Proof of Residence Document";
  public doc_type2 = "ID Document";
  public desc = "";
  public desc2 = "";
  $refs!: Vue["$refs"] & {
    map: typeof VuetifyGoogleAutocomplete;
  };
  public mounted() {
    this.reset();
  }

  public reset() {
    this.showLoading = false;
    if (this.user) {
      this.images = [];
      this.IDFiles = Array();
      this.BillFiles = Array();
      console.log("user", this.user);
      const response = this.user;
      this.user_id = _.get(response, "user_id", "");
      this.unit = _.get(response, "unit", "");
      this.address = formatAddress(response);
      const googleAddress = _.get(response, "google_address_json", null);
      const place = _.get(response, "google_place_json", null);
      const placeId = _.get(response, "google_place_id", null);
      if (googleAddress) {
        this.addressData = JSON.parse(googleAddress);
        console.log("addressdata", this.addressData);
      }
      if (place) {
        this.place = JSON.parse(place);
      }
      if (placeId) {
        this.placeId = placeId;
      }
      this.$refs.map.update(this.address);
    }
  }

  public getAddressData(addressData, placeResultData, id) {
    this.addressData = addressData;
    this.place = placeResultData;
    this.placeId = id;
    this.addressInfo = `Address Detail: ${JSON.stringify(
      addressData
    )} ${JSON.stringify(placeResultData)} ${id}`;
    console.log(addressData, placeResultData, id);
  }

  public updateAddress(newAddress) {
    this.address = newAddress;
  }
  public cancel() {
    this.$router.back();
  }

  public async submit() {
    this.showLoading = true;

    await dispatchUploadAdminDocs(this.$store, {
      user_id: this.user_id,
      id_files: this.IDFiles,
      doc_type: this.doc_type,
      desc: this.desc,
      bill_files: this.BillFiles,
      doc_type2: this.doc_type2,
      desc2: this.desc2,
      is_foreign: this.is_foreign,
    });
    await dispatchEditAddress(this.$store, {
      user_id: this.user_id,
      unit: this.unit,
      address: this.address,
      addressData: `${JSON.stringify(this.addressData)}`,
      placeData: `${JSON.stringify(this.place)}`,
      placeId: `${JSON.stringify(this.placeId)}`,
    }).catch((error) => {
      this.showLoading = false;
      this.tip = "Please select your address from the address selection menu";
    });
    this.showLoading = false;

    this.$props.onClose(this.user.user_id);
  }

  get user() {
    return this.$props.user;
  }

  async uploadImageSuccessID(formData, index, fileList) {
    console.log("data", formData, index, fileList);
    console.log("files", fileList);
    const names = this.IDFiles.map((item) => _.get(item, "name", ""));
    this.IDFiles = fileList; //[...fileList.filter((item) => !names.includes(item.name))];
    console.log("IDFiles", this.IDFiles);
  }
  async uploadImageSuccessBill(formData, index, fileList) {
    console.log("data", formData, index, fileList);
    console.log("files", fileList);
    const names = this.BillFiles.map((item) => _.get(item, "name", ""));
    this.BillFiles = fileList; // [...fileList.filter((item) => !names.includes(item.name))];
    console.log("BillFiles", this.BillFiles);
  }
  beforeRemove(index, done, fileList) {
    console.log("index", index, fileList);
    var r = confirm("remove image");
    if (r == true) {
      done();
    } else {
    }
  }
  editImage(formData, index, fileList) {
    console.log("edit data", formData, index, fileList);
  }

  dataChange(change) {
    console.log("data changed", change);
  }

  validate() {
    if (
      this.IDFiles &&
      this.IDFiles.length > 0 &&
      this.BillFiles &&
      this.BillFiles.length > 0 &&
      !!this.address &&
      !!this.addressData &&
      !!this.place
    ) {
      return true;
    }
    return false;
  }
}
