





























































































import { Component, Vue, Watch } from "vue-property-decorator";
import { api } from "@/api";
import { appName } from "@/env";
import { readLoginError } from "@/store/main/getters";
import VuetifyGoogleAutocomplete from "../../VuetifyGoogleAutocomplete.vue";
import { dispatchUploadAdminDocs } from "@/store/admin/actions";
import { readServiceAddress, readBillingAddress } from "@/store/main/getters";
import _ from "lodash";
import VueUploadMultipleImage from "../../VueUploadMultipleImage.vue";
import UploadButton from "../../../components/UploadButton.vue";

import Loading from "../../Loading.vue";

@Component({
  components: {
    VuetifyGoogleAutocomplete,
    VueUploadMultipleImage,
    Loading,
  },
  props: {
    editInfo: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
    },
    onClose: {
      type: Function,
    },
  },
  watch: {
    user: function (newVal, oldVal) {
      // watch it
      console.log("Prop changed: ", newVal, " | was: ", oldVal);
      const call = _.get(this, "reset", () => null);
      call();
    },
  },
})
export default class UploadDocAdmin extends Vue {
  public doc_types = [
    "ID Document",
    "Proof of Residence Document",
    "CAF Document",
    "Billing Document",
    "Other Document",
  ];
  public images = [];
  public showPrimary = false;
  public multiple = true;

  public IDFiles = Array();
  public BillFiles = Array();
  public showLoading = false;

  public doc_type = "";
  public desc = "";

  public mounted() {
    this.reset();
  }

  async uploadImageSuccessID(formData, index, fileList) {
    console.log("data", formData, index, fileList);
    console.log("files", fileList);
    const names = this.IDFiles.map((item) => _.get(item, "name", ""));
    this.IDFiles = fileList; //[...fileList.filter((item) => !names.includes(item.name))];
    console.log("IDFiles", this.IDFiles);
  }
  async uploadImageSuccessBill(formData, index, fileList) {
    console.log("data", formData, index, fileList);
    console.log("files", fileList);
    const names = this.BillFiles.map((item) => _.get(item, "name", ""));
    this.BillFiles = fileList; // [...fileList.filter((item) => !names.includes(item.name))];
    console.log("BillFiles", this.BillFiles);
  }
  beforeRemove(index, done, fileList) {
    console.log("index", index, fileList);
    var r = confirm("remove image");
    if (r == true) {
      done();
    } else {
    }
  }
  editImage(formData, index, fileList) {
    console.log("edit data", formData, index, fileList);
  }

  dataChange(change) {
    console.log("data changed", change);
  }

  async submit() {
    this.showLoading = true;
    console.log("user", this.user.id);
    await dispatchUploadAdminDocs(this.$store, {
      user_id: this.user.id,
      id_files: this.IDFiles,
      doc_type: this.doc_type,
      desc: this.desc,
      bill_files: this.BillFiles,
    });
    this.showLoading = false;
    this.$props.onClose(this.user.id);
  }
  public reset() {
    this.showLoading = false;
    this.IDFiles = Array();
    this.images = [];
  }

  get user() {
    return this.$props.user;
  }

  validate() {
    if (
      (this.IDFiles && this.IDFiles.length > 0) ||
      (this.BillFiles && this.BillFiles.length > 0)
    ) {
      return true;
    }
    return false;
  }
}
