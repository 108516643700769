




















































import { Component, Vue } from "vue-property-decorator";
import { IUserProfile, IUserProfileUpdate } from "@/interfaces";
import { dispatchGetUsers, dispatchMarketSegment } from "@/store/admin/actions";
import { readAdminOneUser } from "@/store/admin/getters";
import _ from "lodash";
import { formatAddress } from "../../../utils";
import VuetifyGoogleAutocomplete from "../../VuetifyGoogleAutocomplete.vue";
import { dispatchUploadAdminDocs } from "@/store/admin/actions";
import { readServiceAddress, readBillingAddress } from "@/store/main/getters";

import Loading from "../../Loading.vue";

@Component({
  components: {
    Loading,
  },
  props: {
    editInfo: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
    },
    market: {
      type: Object,
    },
    onClose: {
      type: Function,
    },
  },
  watch: {
    user: function (newVal, oldVal) {
      // watch it
      console.log("Prop changed: ", newVal, " | was: ", oldVal);
      const call = _.get(this, "reset", () => null);
      call();
    },
    market: function (newVal, oldVal) {
      // watch it
      console.log("Prop changed: ", newVal, " | was: ", oldVal);
      const call = _.get(this, "reset", () => null);
      call();
    },
  },
})
export default class MarketSegment extends Vue {
  public valid = true;
  public dialog = false;
  public segment = "";
  public description = "";
  public showLoading = false;
  public tip = "";

  public segment_types = ["Switcher", "New Tenant", "Other"];

  public mounted() {
    this.reset();
  }

  public reset() {
    this.showLoading = false;
    if (this.$props.market) {
      this.segment = _.get(this.$props, "market.segment", "");
      this.description = _.get(this.$props, "market.description", "");
    }
  }

  public async submit() {
    this.showLoading = true;

    await dispatchMarketSegment(this.$store, {
      user_id: this.$props.user.user.id,
      segment: this.segment,
      description: this.description,
    });

    this.showLoading = false;

    this.$props.onClose(this.$props.user.user.id);
  }
}
