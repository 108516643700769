



























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { appName, merchantURL, appUrl } from "@/env";
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { IUserProfile } from "@/interfaces";
import { readUserProfile } from "@/store/main/getters";
import {
  dispatchGetUsers,
  dispatchGetUser,
  dispatchSendToPayment,
  dispatchSendToIDUpload,
  dispatchNotFeasible,
  dispatchNotActivated,
  dispatchResetProgress,
  dispatchDeleteDoc,
  dispatchScheduleConfirm,
  dispatchActivationConfirm,
  dispatchRecurringBill,
  dispatchRefundBillingItem,
  dispatchRefundReBillingItem,
  dispatchPaymentReminder,
  dispatchAddRebate,
  dispatchPostDeleteUser,
  dispatchGetRebillSummary,
  dispatchRecurBill,
  dispatchSendReferReminder,
  dispatchSendReferReminderToSender,
  dispatchManualPayToggle,
  dispatchMarketSegment,
  dispatchInactivate,
} from "@/store/admin/actions";
import _ from "lodash";
import moment, { duration } from "moment-timezone";
import Apply from "../../Apply.vue";
import EditUser from "./EditUser.vue";
import UploadUserDoc from "./UploadUserDoc.vue";
import EditAddress from "./EditAddress.vue";
import MarketSegment from "./MarketSegment.vue";
import Loading from "../../Loading.vue";
import UploadReceipt from "./UploadReceipt.vue";
@Component({
  components: {
    Apply,
    EditUser,
    UploadUserDoc,
    EditAddress,
    MarketSegment,
    Loading,
    UploadReceipt,
  },
  props: {
    user_id: {
      type: Number,
    },
    scroll_item: {
      type: String,
    },
  },
  watch: {
    user_id: function (newVal, oldVal) {
      // watch it
      console.log("Prop changed: ", newVal, " | was: ", oldVal);
      const call = _.get(this, "reset", () => null);
      call(newVal);
    },
    scroll_item: function (newVal, oldVal) {
      // watch it
      console.log("Prop changed: ", newVal, " | was: ", oldVal);
      if (newVal) {
        setTimeout(() => {
          document.getElementById(newVal).scrollIntoView(true);
        }, 2000);
      }
    },
  },
})
export default class AdminViewUser extends Vue {
  public search = "";
  public dialog = false;
  public showLoading = false;
  public dialogUploadReceipt = false;
  public receipt_id = "";

  public orderHeaders = [
    {
      text: "Order ID",
      sortable: true,
      value: "id",
      align: "left",
    },
    {
      text: "Plan",
      sortable: true,
      value: "service",
      align: "left",
    },
    {
      text: "Router Payment Method",
      sortable: true,
      value: "router_ptype",
      align: "left",
    },
    {
      text: "Order Type",
      sortable: true,
      value: "order_type",
      align: "left",
    },
    {
      text: "Qty",
      sortable: true,
      value: "quantity",
      align: "left",
    },
    {
      text: "Price",
      sortable: true,
      value: "price",
      align: "left",
    },
    {
      text: "Currency",
      sortable: true,
      value: "currency",
      align: "left",
    },
    {
      text: "Date",
      sortable: true,
      value: "created_date",
      align: "left",
    },
    {
      text: "Month",
      sortable: true,
      value: "month",
      align: "left",
    },
    {
      text: "Year",
      sortable: true,
      value: "year",
      align: "left",
    },
    {
      text: "Terms",
      sortable: true,
      value: "terms",
      align: "left",
    },
    {
      text: "Promo Code",
      sortable: true,
      value: "promo_code",
      align: "left",
    },
    {
      text: "Promo Description",
      sortable: true,
      value: "promoDesc",
      align: "left",
    },
  ];

  public billingHeaders = [
    {
      text: "Paynamics Ref",
      sortable: true,
      value: "request_id",
      align: "left",
    },
    {
      text: "Plan",
      sortable: true,
      value: "title",
      align: "left",
    },
    {
      text: "Payment Method",
      sortable: true,
      value: "ptype",
      align: "left",
    },
    {
      text: "Order ID",
      sortable: true,
      value: "order_id",
      align: "left",
    },
    {
      text: "Router Payment Method",
      sortable: true,
      value: "router_ptype",
      align: "left",
    },
    {
      text: "Price",
      sortable: true,
      value: "price",
      align: "left",
    },

    {
      text: "Paynamics Response Code",
      sortable: true,
      value: "response_code",
      align: "left",
    },

    {
      text: "Settled",
      sortable: true,
      value: "is_settled",
      align: "left",
    },
    {
      text: "Date",
      sortable: true,
      value: "created_date",
      align: "left",
    },
    {
      text: "Month",
      sortable: true,
      value: "month",
      align: "left",
    },
    {
      text: "Year",
      sortable: true,
      value: "year",
      align: "left",
    },
    {
      text: "Actions",
      value: "actions",
    },
  ];

  public rebillingHeaders = [
    {
      text: "Paynamics Ref",
      sortable: true,
      value: "request_id",
      align: "left",
    },
    {
      text: "Plan",
      sortable: true,
      value: "title",
      align: "left",
    },
    {
      text: "Payment Method",
      sortable: true,
      value: "ptype",
      align: "left",
    },
    {
      text: "Price",
      sortable: true,
      value: "price",
      align: "left",
    },
    {
      text: "Paynamics Response Code",
      sortable: true,
      value: "response_code",
      align: "left",
    },

    {
      text: "Settled",
      sortable: true,
      value: "is_settled",
      align: "left",
    },
    {
      text: "Date",
      sortable: true,
      value: "created_date",
      align: "left",
    },
    {
      text: "Month",
      sortable: true,
      value: "month",
      align: "left",
    },
    {
      text: "Year",
      sortable: true,
      value: "year",
      align: "left",
    },
    {
      text: "Actions",
      value: "actions",
    },
  ];

  public refundHeaders = [
    {
      text: "Paynamics Ref",
      sortable: true,
      value: "request_id",
      align: "left",
    },
    {
      text: "Plan",
      sortable: true,
      value: "title",
      align: "left",
    },
    {
      text: "Payment Method",
      sortable: true,
      value: "ptype",
      align: "left",
    },
    {
      text: "Created Date",
      sortable: true,
      value: "created_date",
      align: "left",
    },
    {
      text: "Month",
      sortable: true,
      value: "month",
      align: "left",
    },
    {
      text: "Year",
      sortable: true,
      value: "year",
      align: "left",
    },
    {
      text: "Created By",
      sortable: true,
      value: "created_by",
      align: "left",
    },
    {
      text: "Invoice ID",
      sortable: true,
      value: "request_id",
      align: "left",
    },
    {
      text: "QB ID",
      sortable: true,
      value: "qb_journal_id ",
      align: "left",
    },
    {
      text: "Price",
      sortable: true,
      value: "price",
      align: "left",
    },
    {
      text: "Is Refunded",
      sortable: true,
      value: "is_refunded",
      align: "left",
    },
    {
      text: "Is Chargeback",
      sortable: true,
      value: "is_chargeback",
      align: "left",
    },
  ];

  public receiptHeaders = [
    {
      text: "Receipt ID",
      sortable: true,
      value: "TranID",
      align: "left",
    },
    {
      text: "Plan",
      sortable: true,
      value: "plan_name",
      align: "left",
    },
    {
      text: "Payment Method",
      sortable: true,
      value: "pmethod",
      align: "left",
    },
    {
      text: "Created Date",
      sortable: true,
      value: "created_date",
      align: "left",
    },
    {
      text: "Month",
      sortable: true,
      value: "month",
      align: "left",
    },
    {
      text: "Year",
      sortable: true,
      value: "year",
      align: "left",
    },
    {
      text: "Amount",
      sortable: true,
      value: "TranAmt",
      align: "left",
    },
    {
      text: "Promo Code",
      sortable: true,
      value: "promo_code",
      align: "left",
    },
    {
      text: "Promo Cost",
      sortable: true,
      value: "promo_cost",
      align: "left",
    },
    {
      text: "Actions",
      value: "actions",
    },
    {
      text: "Upload Receipt",
      value: "upload_receipt",
    },
    {
      text: "Upload Date",
      value: "manual_uploaded_date",
    },
  ];

  public promoCostHeaders = [
    {
      text: "Created Date",
      sortable: true,
      value: "created_date",
      align: "left",
    },
    {
      text: "Plan",
      sortable: true,
      value: "service_name",
      align: "left",
    },
    {
      text: "Payment Method",
      sortable: true,
      value: "ptype",
      align: "left",
    },
    {
      text: "Month",
      sortable: true,
      value: "month",
      align: "left",
    },
    {
      text: "Year",
      sortable: true,
      value: "year",
      align: "left",
    },

    {
      text: "Amount",
      sortable: true,
      value: "amount",
      align: "left",
    },

    {
      text: "Promo Code",
      sortable: true,
      value: "promo_code",
      align: "left",
    },
    {
      text: "Promo Description",
      sortable: true,
      value: "promo_desc",
      align: "left",
    },
    {
      text: "Comment",
      sortable: true,
      value: "promo_comment",
      align: "left",
    },
  ];

  public invoiceHeaders = [
    {
      text: "Invoice ID",
      sortable: true,
      value: "BillingRef",
      align: "left",
    },
    {
      text: "Plan",
      sortable: true,
      value: "plan_name",
      align: "left",
    },
    {
      text: "Payment Method",
      sortable: true,
      value: "pmethod",
      align: "left",
    },
    {
      text: "Created Date",
      sortable: true,
      value: "created_date",
      align: "left",
    },
    {
      text: "Month",
      sortable: true,
      value: "month",
      align: "left",
    },
    {
      text: "Year",
      sortable: true,
      value: "year",
      align: "left",
    },
    {
      text: "Amount",
      sortable: true,
      value: "TranAmt",
      align: "left",
    },
    {
      text: "Actions",
      value: "actions",
    },
  ];

  public soaHeaders = [
    {
      text: "SOA ID",
      sortable: true,
      value: "SOANo",
      align: "left",
    },
    {
      text: "Plan",
      sortable: true,
      value: "plan_name",
      align: "left",
    },
    {
      text: "Payment Method",
      sortable: true,
      value: "pmethod",
      align: "left",
    },
    {
      text: "Created Date",
      sortable: true,
      value: "created_date",
      align: "left",
    },
    {
      text: "Month",
      sortable: true,
      value: "month",
      align: "left",
    },
    {
      text: "Year",
      sortable: true,
      value: "year",
      align: "left",
    },
    {
      text: "Previous Balance",
      sortable: true,
      value: "PreviousBalance",
      align: "left",
    },
    {
      text: "Current Balance",
      sortable: true,
      value: "CurrentBalance",
      align: "left",
    },
    {
      text: "Total Amount Due",
      sortable: true,
      value: "TotalAmt",
      align: "left",
    },
    {
      text: "Actions",
      value: "actions",
    },
  ];

  public rebateHeaders = [
    {
      text: "Rebate ID",
      sortable: true,
      value: "id",
      align: "left",
    },
    {
      text: "Created Date",
      sortable: true,
      value: "created_date",
      align: "left",
    },
    {
      text: "Created By",
      sortable: true,
      value: "created_by",
      align: "left",
    },
    {
      text: "Month",
      sortable: true,
      value: "month",
      align: "left",
    },
    {
      text: "Year",
      sortable: true,
      value: "year",
      align: "left",
    },
    {
      text: "Amount",
      sortable: true,
      value: "amount",
      align: "left",
    },
    {
      text: "Remaining Amount",
      sortable: true,
      value: "remaining_amount",
      align: "left",
    },
    {
      text: "Comment",
      sortable: true,
      value: "comment",
      align: "left",
    },
  ];

  public pointsHeaders = [
    {
      text: "User ID",
      sortable: true,
      value: "id",
      align: "left",
    },
    {
      text: "Created Date",
      sortable: true,
      value: "created_date",
      align: "left",
    },
    {
      text: "Referral ID",
      sortable: true,
      value: "referral_id",
      align: "left",
    },
    {
      text: "Month",
      sortable: true,
      value: "month",
      align: "left",
    },
    {
      text: "Year",
      sortable: true,
      value: "year",
      align: "left",
    },
    {
      text: "Action",
      sortable: true,
      value: "action",
      align: "left",
    },
    {
      text: "Desc",
      sortable: true,
      value: "action_desc",
      align: "left",
    },
    {
      text: "Amount",
      sortable: true,
      value: "amount",
      align: "left",
    },
    {
      text: "Balance",
      sortable: true,
      value: "balance",
      align: "left",
    },
    {
      text: "Redemption Code",
      sortable: true,
      value: "redemption_code",
      align: "left",
    },
    {
      text: "Redeemed",
      sortable: true,
      value: "is_redeemed",
      align: "left",
    },
  ];

  public rebillRecordsHeaders = [
    {
      text: "date",
      sortable: true,
      value: "date",
      align: "left",
    },
    {
      text: "Plan",
      sortable: true,
      value: "plan_name",
      align: "left",
    },
    {
      text: "Payment Method",
      sortable: true,
      value: "pmethod",
      align: "left",
    },
    {
      text: "month",
      sortable: true,
      value: "month",
      align: "left",
    },
    {
      text: "year",
      sortable: true,
      value: "year",
      align: "left",
    },
    {
      text: "amount",
      sortable: true,
      value: "amount",
      align: "left",
    },
    {
      text: "remaining_amount",
      sortable: true,
      value: "remaining_amount",
      align: "left",
    },
    {
      text: "splynx_invoice_ref",
      sortable: true,
      value: "splynx_invoice_ref",
      align: "left",
    },
    {
      text: "paynamics_request_id",
      sortable: true,
      value: "paynamics_request_id",
      align: "left",
    },
    {
      text: "qb_recognize_ref",
      sortable: true,
      value: "qb_recognize_ref",
      align: "left",
    },
    {
      text: "qb_invoice_ref",
      sortable: true,
      value: "qb_invoice_ref",
      align: "left",
    },
    {
      text: "qb_invoice_ref",
      sortable: true,
      value: "qb_invoice_ref",
      align: "left",
    },
    {
      text: "qb_payment_ref",
      sortable: true,
      value: "qb_payment_ref",
      align: "left",
    },
    {
      text: "qb_prepayment_ref",
      sortable: true,
      value: "qb_prepayment_ref",
      align: "left",
    },
    {
      text: "promo_code",
      sortable: true,
      value: "promo_code",
      align: "left",
    },
  ];
  public editedIndex = -1;
  public editedItem = {
    id: -1,
  };
  public editedUser = {};
  public defaultItem = {};
  public rebate = "";
  public rebate_comment = "";
  public dialogMini = false;
  public dialogMini2 = false;
  public dialogMini3 = false;
  public dialogRebate = false;
  public dialogRebill = false;
  public dialogCB = false;
  public dialogRecur = false;
  public dialogEditAddress = false;
  public dialogEditInfo = false;
  public dialogEditDoc = false;
  public recurDate = "";

  public installationDate = "";
  public duration_hour = 2;
  public duration_minute = 0;
  public is_auto_pay = false;
  public rebillSummary = {};
  public userToEdit = {};
  public num_recur = 1;
  public onlyAdmin = false;
  public onlyPaid = false;
  public onlySuperUsers = false;
  public showInactive = true;

  $refs!: Vue["$refs"] & {
    edit_address: typeof EditAddress;
    upload_doc: typeof UploadUserDoc;
    edit_info: typeof EditUser;
    upload_receipt: typeof UploadReceipt;
  };

  created() {
    if (location.host.startsWith("dev")) {
      document.title = "Dev: User Administration";
    } else {
      document.title = "User Administration";
    }
  }
  get hours() {
    return [...Array(24).keys()];
  }
  get minutes() {
    return [...Array(60).keys()];
  }

  get_promo_desc(editedUser, item) {
    const promo_id = item.promo_id;
    let promo_desc = "";
    editedUser.promos.forEach((promo) => {
      if (promo.id == promo_id) {
        promo_desc = `${promo.description}`;
      }
    });
    return promo_desc;
  }
  get_service_name(editedUser, item) {
    const service_id = item.service_id;
    let service_name = "";
    editedUser.services.forEach((service) => {
      if (service.id == service_id) {
        service_name = `${service.name} (${service.speed} ${service.speed_unit})`;
      }
    });
    return service_name;
  }
  get_terms(user, item) {
    if (user.activation_date) {
      let date1 = this.formatDateOnly(moment(user.activation_date));
      if (item.contract_start_date > user.activation_date) {
        date1 = this.formatDateOnly(moment(item.contract_start_date));
      }
      let date2 = this.formatDateOnly(
        moment(item.contract_start_date || user.activation_date)
          .add(item.contract_length, "months")
          .subtract(1, "day")
      );
      if (item.is_upgrade) {
        date2 = this.formatDateOnly(
          moment(user.activation_date)
            .add(item.contract_length, "months")
            .subtract(1, "day")
        );
      }

      return `${date1} to ${date2}`;
    } else {
      return `${user.contract_length} Months`;
    }
  }

  reset = async (user_id) => {
    this.editItem({ id: user_id });
  };

  async editItem(user) {
    console.log("item", user);
    if (user) {
      dispatchGetUser(this.$store, user).then((response) => {
        this.editedUser = _.get(response, "data", {});
        console.log("view info", this.editedUser);
        this.is_auto_pay = !_.get(this.editedUser, "user.is_manual_pay", false);
        this.dialog = true;
      });
    }
  }

  inactiveCustomer(user_id) {
    dispatchInactivate(this.$store, user_id);
  }

  getPmethod(item) {
    if (item.billing_id) {
      const bills = _.get(this.editedUser, "bills", []);
      return _.get(
        _.first(bills.filter((bill) => bill.id == item.billing_id)),
        "ptype",
        ""
      );
    } else if (item.rebill_id) {
      const bills = _.get(this.editedUser, "rebills", []);
      return _.get(
        _.first(bills.filter((bill) => bill.id == item.rebill_id)),
        "ptype",
        ""
      );
    }
  }

  formatDate(date) {
    if (date) {
      return moment.utc(date).tz("Asia/Manila").format("MMM DD, YYYY HH:mm:ss");
    }
    return "";
  }
  formatDateOnly(date) {
    if (date) {
      return moment.utc(date).tz("Asia/Manila").format("MMM DD, YYYY");
    }
    return "";
  }
  formatMonthSplynx(splynx_items_json) {
    if (splynx_items_json) {
      const items = _.get(JSON.parse(splynx_items_json), "items", []);
      let date = null;
      items.forEach((item) => {
        if (!date || (item.period_from && moment(item.period_from) > date)) {
          date = moment(item.period_from);
        }
      });
      if (date) {
        return moment.utc(date).tz("Asia/Manila").format("MM");
      } else {
        return null;
      }
    }
    return "";
  }
  formatMonth(date) {
    if (date) {
      return moment.utc(date).tz("Asia/Manila").format("MM");
    }
    return "";
  }
  formatYear(date) {
    if (date) {
      return moment.utc(date).tz("Asia/Manila").format("YYYY");
    }
    return "";
  }

  formatDoc(item) {
    const type = _.get(item, "document_type", "id");
    if (type) {
      return type.toUpperCase();
    } else {
      return "";
    }
  }
  close() {
    this.dialog = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({ id: -1 }, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  getDocLink(user, doc_id) {
    return `/api/v1/users/document/${user.user.id}/${doc_id}/${user.token}/`;
  }

  getPDFLink(user, type, doc_id) {
    return `/api/v1/users/pdf/${user.user.id}/${type}/${doc_id}/${user.token}/`;
  }
  save() {
    if (this.editedIndex > -1) {
      //Object.assign(this.desserts[this.editedIndex], this.editedItem)
    } else {
      //this.desserts.push(this.editedItem)
    }
    this.close();
  }

  isPDF(name) {
    if (name.toString().toLowerCase().includes("pdf")) {
      return true;
    }
    return false;
  }

  public async sendRecurConfirm(user_id) {
    this.showLoading = true;
    this.dialogRecur = false;
    const result = await dispatchRecurBill(this.$store, {
      user_id: user_id,
      num_recur: this.num_recur,
      date: this.recurDate,
    });
    const text = _.get(result, "data.result", "");
    alert(text);
    console.log("recur bill", text);
    await dispatchGetUsers(this.$store);

    await this.editItem({
      id: user_id,
    });
    this.showLoading = false;
  }
  public async sendToNotFeasible(user_id) {
    await dispatchNotFeasible(this.$store, user_id);
    await dispatchGetUsers(this.$store);

    await this.editItem({
      id: user_id,
    });
  }
  public async sendToNotActivated(user_id) {
    await dispatchNotActivated(this.$store, user_id);
    await dispatchGetUsers(this.$store);

    await this.editItem({
      id: user_id,
    });
  }

  public async showRebillSummary(user_id) {
    const bill = await dispatchGetRebillSummary(this.$store, {
      user_id: user_id,
    });
    this.rebillSummary = _.get(bill, "data.payment", {});
    console.log("rebill summary", this.rebillSummary);
    this.dialogRebill = true;
  }

  public async showRecur(user_id) {
    this.dialogRecur = true;
  }
  public getItems(item) {
    console.log("items", item.splynx_items_json);
    return _.get(item, "splynx_items_json.items", []);
  }
  public async deleteDoc(user_id, doc_id) {
    await dispatchDeleteDoc(this.$store, {
      userId: user_id,
      docId: doc_id,
    });
    await dispatchGetUsers(this.$store);

    await this.editItem({
      id: user_id,
    });
  }

  getApiUrl() {
    return appUrl;
  }

  public async sendToIDUpload(user_id) {
    await dispatchSendToIDUpload(this.$store, user_id);
    await dispatchGetUsers(this.$store);

    await this.editItem({
      id: user_id,
    });
  }
  public async sendToPayment(user_id) {
    await dispatchSendToPayment(this.$store, user_id);
    await dispatchGetUsers(this.$store);

    await this.editItem({
      id: user_id,
    });
  }

  public async sendScheduleConfirm(user_id) {
    if (this.installationDate) {
      this.dialogMini2 = false;
      await dispatchScheduleConfirm(this.$store, {
        user_id: user_id,
        schedule_date: this.installationDate,
        hours: this.duration_hour,
        minutes: this.duration_minute,
      });
      await dispatchGetUsers(this.$store);

      await this.editItem({
        id: user_id,
      });
    }
  }

  public async closeInstallationDialog(user_id) {
    this.dialogMini2 = false;
    await dispatchGetUsers(this.$store);

    await this.editItem({
      id: user_id,
    });
  }
  public async giveRebate(user_id) {
    this.showLoading = true;
    this.dialogRebate = false;
    await dispatchAddRebate(this.$store, {
      user_id: user_id,
      amount: this.rebate,
      comment: this.rebate_comment,
    });
    await dispatchGetUsers(this.$store);

    await this.editItem({
      id: user_id,
    });
    this.showLoading = false;
  }

  public async closeRebateDialog(user_id) {
    this.dialogRebate = false;
    await dispatchGetUsers(this.$store);

    await this.editItem({
      id: user_id,
    });
  }

  public async sendActivationConfirm(
    user_id,
    update = false,
    do_not_process = false
  ) {
    this.showLoading = true;
    await dispatchActivationConfirm(this.$store, {
      user_id: user_id,
      update: update,
      do_not_process: do_not_process,
    });
    await dispatchGetUsers(this.$store);

    await this.editItem({
      id: user_id,
    });
    alert("Activation Confirmed");
    this.showLoading = false;
  }

  public async sendNotFeasible(user_id) {
    await dispatchNotFeasible(this.$store, user_id);
    await dispatchGetUsers(this.$store);

    await this.editItem({
      id: user_id,
    });
  }

  public async sendToResetStatus(user_id) {
    await dispatchResetProgress(this.$store, user_id);
    await dispatchGetUsers(this.$store);

    await this.editItem({
      id: user_id,
    });
  }

  public async deleteUser(user_id) {
    const msg = `Do you really want to Delete this user? This process is irreversible.`;
    this.$confirm(msg).then(async (res) => {
      console.log(res);
      if (res) {
        await dispatchPostDeleteUser(this.$store, {
          user_id,
        });
        this.close();
        await dispatchGetUsers(this.$store);
      }
    });
  }

  public async sendPaymentReminder(user_id) {
    this.showLoading = true;
    await dispatchPaymentReminder(this.$store, {
      user_id: user_id,
    });
    await dispatchGetUsers(this.$store);

    await this.editItem({
      id: user_id,
    });
    this.showLoading = false;
  }

  public async sendSendReferReminder(user_id) {
    await dispatchSendReferReminder(this.$store, {
      user_id: user_id,
    });
    await dispatchGetUsers(this.$store);

    await this.editItem({
      id: user_id,
    });
  }

  public async sendSendReferReminderToSender(user_id) {
    await dispatchSendReferReminderToSender(this.$store, {
      user_id: user_id,
    });
    await dispatchGetUsers(this.$store);

    await this.editItem({
      id: user_id,
    });
  }

  public async sendRecurringBill(user_id) {
    this.dialogRebill = false;
    this.showLoading = true;
    const result = await dispatchRecurringBill(this.$store, {
      user_id: user_id,
    });
    const billing = _.get(result, "data.result", {});
    console.log("Rebill", billing);
    if (!billing.is_settled) {
      alert(
        `The Rebill transaction was not successful. Bank Response: ${billing.response_code} ${billing.response_message}`
      );
    } else {
      alert(`The Rebill transaction was successful. `);
    }
    await dispatchGetUsers(this.$store);

    await this.editItem({
      id: user_id,
    });
    this.showLoading = false;
  }
  public cbData: any = null;
  public cb_ref = "";
  public async showCBilling(user_id, billing_id) {
    this.cbData = {
      bill_id: billing_id,
      is_chargeback: true,
    };
    this.dialogCB = true;
  }
  public async showCReBilling(user_id, rebilling_id) {
    this.cbData = {
      rebill_id: rebilling_id,
      is_chargeback: true,
    };
    this.dialogCB = true;
  }
  public async processCB(user_id) {
    this.dialogCB = false;
    if ("bill_id" in this.cbData) {
      //alert("bill");
      const bill_id = _.get(this.cbData, "bill_id", "");
      if (bill_id) {
        this.refundBillingItem(user_id, bill_id, true);
      }
    }
    if ("rebill_id" in this.cbData) {
      //alert("rebill");
      const rebill_id = _.get(this.cbData, "rebill_id", "");
      if (rebill_id) {
        this.refundReBillingItem(user_id, this.cbData.rebill_id, true);
      }
    }
  }
  public async processRefund(user_id, billing_id, is_chargeback = false) {
    const result = await dispatchRefundBillingItem(this.$store, {
      bill_id: billing_id,
      is_chargeback: is_chargeback,
      cb_ref: this.cb_ref,
    });
    const billing = _.get(result, "data.result", {});
    console.log(billing);
    let item = "Refund";
    if (is_chargeback) {
      item = "Chargeback";
    }
    if (!billing.is_settled) {
      alert(
        `The ${item} transaction was not successful. Please contact the bank for details or try again in 24 hours. Bank Response: ${billing.response_code} ${billing.response_message}`
      );
    } else {
      alert(`The ${item} transaction was successful. `);
    }
    await dispatchGetUsers(this.$store);

    await this.editItem({
      id: user_id,
    });
  }
  public async refundBillingItem(user_id, billing_id, is_chargeback = false) {
    const msg = `Do you really want to process the ${
      is_chargeback ? "Chargeback" : "Refund"
    }? This process is irreversible.`;
    this.$confirm(msg).then((res) => {
      console.log(res);
      if (res) {
        this.processRefund(user_id, billing_id, is_chargeback);
      }
    });
  }

  public async processRebillRefund(
    user_id,
    rebilling_id,
    is_chargeback = false
  ) {
    const result = await dispatchRefundReBillingItem(this.$store, {
      rebill_id: rebilling_id,
      is_chargeback: is_chargeback,
      cb_ref: this.cb_ref,
    });
    const billing = _.get(result, "data.result", {});
    console.log(billing);
    let item = "Refund";
    if (is_chargeback) {
      item = "Chargeback";
    }
    if (!billing.is_settled) {
      alert(
        `The ${item} transaction was not successful. Please contact the bank for details or try again in 24 hours. Bank Response: ${billing.response_code} ${billing.response_message}`
      );
    } else {
      alert(`The ${item} transaction was successful. `);
    }
    await dispatchGetUsers(this.$store);

    await this.editItem({
      id: user_id,
    });
  }
  public async refundReBillingItem(
    user_id,
    rebilling_id,
    is_chargeback = false
  ) {
    const msg = `Do you really want to process the ${
      is_chargeback ? "Chargeback" : "Refund"
    }? This process is irreversible.`;
    this.$confirm(msg).then((res) => {
      console.log(res);
      if (res) {
        this.processRebillRefund(user_id, rebilling_id, is_chargeback);
      }
    });
  }

  public getUserToEdit() {
    return this.userToEdit;
  }
  public async showEditInfo(user) {
    this.userToEdit = user;
    // this.dialogEditUser = true;
    this.dialogEditInfo = true;
    setTimeout(() => {
      this.$refs.edit_info.reset();
    }, 100);
  }
  public async closeEditInfo(user_id) {
    this.dialogEditInfo = false;
    this.dialog = false;

    await dispatchGetUsers(this.$store);

    await this.editItem({
      id: user_id,
    });
  }

  public async toggleManualPay(user) {
    await dispatchManualPayToggle(this.$store, {
      user_id: user.id,
      is_manual_pay: !this.is_auto_pay,
    });
    await dispatchGetUsers(this.$store);

    await this.editItem({
      id: user.id,
    });
  }
  public async refreshDialog(user_id) {
    this.dialog = false;

    await dispatchGetUsers(this.$store);

    await this.editItem({
      id: user_id,
    });
  }

  public async closeDialog(user_id) {
    this.dialog = false;

    await dispatchGetUsers(this.$store);
  }

  public async showEditDoc(user) {
    this.userToEdit = user;
    this.dialogEditDoc = true;
    setTimeout(() => {
      this.$refs.upload_doc.reset();
    }, 100);
  }

  public async showUploadReceipt(user, receipt_id) {
    this.userToEdit = user;
    this.receipt_id = `${receipt_id}`;
    this.dialogUploadReceipt = true;
    setTimeout(() => {
      this.$refs.upload_receipt.reset();
    }, 100);
  }

  public async closeUploadReceipt(user_id) {
    this.dialogUploadReceipt = false;
    this.dialog = false;

    await dispatchGetUsers(this.$store);

    await this.editItem({
      id: user_id,
    });
  }
  public async closeEditDoc(user_id) {
    this.dialogEditDoc = false;
    this.dialog = false;

    await dispatchGetUsers(this.$store);

    await this.editItem({
      id: user_id,
    });
  }

  public async showEditAddress(user) {
    this.userToEdit = user;
    this.dialogEditAddress = true;
    setTimeout(() => {
      this.$refs.edit_address.reset();
    }, 100);
  }
  public async closeEditAddress(user_id) {
    this.dialogEditAddress = false;
    this.dialog = false;

    await dispatchGetUsers(this.$store);

    await this.editItem({
      id: user_id,
    });
  }

  toggleOnlyAdmin() {
    this.onlyAdmin = !this.onlyAdmin;
  }

  toggleOnlySuper() {
    this.onlySuperUsers = !this.onlySuperUsers;
  }

  toggleOnlyPaid() {
    this.onlyPaid = !this.onlyPaid;
  }
  public async toggleShowInactive() {
    this.showInactive = !this.showInactive;
  }
}
